import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import { Tag } from '../Tag';
import { Button } from '../Button';
import { Popup } from '../Popup';
import { Input } from '../Input';
import * as Icons from '../Icons';
import { useToken } from '../TokenContext';
import { api, isError } from '../../helper/api';
import { unknown } from '../../helper/unknown'

import styles from './TagSelector.module.scss';

type TagType = {
    id: number
    name: string
    amount?: number
}

type Props = {
    title: string
    action: string
    tags: TagType[]
    value: number[]
    onChange: (value: number[]) => void
    onUpdateTags?: (tags: TagType[]) => void
    onCancel: () => void
}

type State = {
    type: 'idle'
} | {
    type: 'creating'
    name: string
}

export const TagSelector = ({ title, action, tags, value, onChange, onCancel, onUpdateTags }: Props) => {
    const token = useToken();
    const [ state, setState ] = useState<State>({ type: 'idle' });
    const [ currentValue, setCurrentValue ] = useState<number[]>(value);

    useEffect(() => setCurrentValue(value), [ value ]);

    const toggle = (tag: number) => setCurrentValue(
        currentValue.includes(tag)
            ? currentValue.filter(
                id => id !== tag
            ) : currentValue.concat([ tag ])
    );

    const ids = tags.map(({ id }) => id);
    const selected = ids.length === currentValue.length;

    switch (state.type) {
        case 'idle':
            return (
                <Popup
                    title={ title }
                    action={ <Icons.Check className={ cx({
                        [styles.all]: true,
                        [styles.selected]: selected
                    }) } onClick={() => setCurrentValue(selected ? [] : ids)} /> }
                    actions={(
                        <section className={ styles.actions }>
                            <Button onClick={ () => onChange(currentValue) }>{ action }</Button>
                            <Button onClick={ onCancel }>Cancel</Button>
                        </section>
                    )}
                >
                    { tags.map(
                        ({ id, name, amount }) => (
                            <Tag
                                key={ id }
                                selected={ currentValue.includes(id) }
                                amount={ amount }
                                onClick={() => toggle(id)}
                            >
                                {name}
                            </Tag>
                        )
                    ) }
                    { onUpdateTags && <Tag simple onClick={ () => setState({ type: 'creating', name: '' }) }>+</Tag> }
                </Popup>
            );

        case 'creating':
            const addTag = async () => {
                if (!onUpdateTags) {
                    return
                }

                const result = await api.addTag(state.name, token);

                if (isError(result)) {
                    return alert(result.error);
                }

                onUpdateTags([
                    ...tags,
                    {
                        id: result.id,
                        name: state.name
                    }
                ]);

                setState({ type: 'idle' });
            };

            return (
                <Popup
                    title='Tag title'
                    actions={(
                        <section className={ styles.actions }>
                            <Button onClick={ addTag }>Create</Button>
                            <Button onClick={ () => setState({ type: 'idle' }) }>Cancel</Button>
                        </section>
                    )}
                >
                    <div className={ styles.form }>
                        <Input
                            autoFocus
                            value={ state.name }
                            onChange={ name => setState({
                                ...state,
                                name
                            }) }
                            />
                    </div>
                </Popup>
            );

        default:
            return unknown(state)
    }
};
