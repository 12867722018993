import React, { useState } from 'react';

import { Login } from '../Login';
import { Container } from '../Container';
import { TokenProvider } from '../TokenContext';
import { OfflineProvider } from '../OfflineProvider';

export const App = () => {
    const [ token, setToken ] = useState<string | null>(localStorage.getItem('token'))

    return (
        token
            ? (
                <OfflineProvider>
                    <TokenProvider token={ token }>
                        <Container onNotLoggedIn={() => {
                            localStorage.removeItem('token');

                            setToken(null); 
                        }} />
                    </TokenProvider>
                </OfflineProvider>
            ) : <Login onLogin={(token) => {
                localStorage.setItem('token', token);

                setToken(token);
            }} />
    )
}