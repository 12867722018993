import React, { ChangeEvent } from 'react';
import cx from 'classnames';

import * as Icons from '../Icons';

import styles from './ReachInput.module.scss';

type Props = {
    value: string
    info?: string
    onChange: (value: string) => void
    extended?: boolean
    onExtended: () => void
}

export const ReachInput = ({ value, info, extended, onChange, onExtended }: Props) => (
    <div className={ styles.container }>
        <input
            type='text'
            className={ styles.input }
            autoFocus
            value={ value }
            onChange={ ({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.value) }
        />
        { info && <div className={ styles.info }>{ info }</div> }
        <div className={ styles.bar } />
        <button className={ styles.button } onClick={ () => onChange('') }>
            <Icons.Cross className={ styles.icon } />
        </button>
        <button className={ cx(styles.button, {
            [styles.active]: extended
        }) } onClick={ onExtended }>
            <Icons.Items className={ styles.icon } />
        </button>
    </div>
)