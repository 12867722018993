import React, { createContext, useContext } from 'react';

const LoginContext = createContext<string>('');

type Props = {
    token: string
};

export const TokenProvider: React.FC<Props> = ({ token, children }) => (
    <LoginContext.Provider value={ token }>
        { children }
    </LoginContext.Provider>
);

export const useToken = () => useContext(LoginContext);