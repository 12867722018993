import React, { useState, useEffect } from 'react';

import { Processing } from '../Processing';
import { Popup } from '../Popup';
import { Button } from '../Button';
import { Left } from '../Left';
import * as Icons from '../Icons';
import { useToken } from '../TokenContext';
import { useOffline } from '../OfflineProvider';
import { api, isError, Article as ArticleType, Tag as TagType } from '../../helper/api';
import { unknown } from '../../helper/unknown';
import { getDB } from '../../helper/db';
import { left } from '../../helper/left';

import { Article } from './Article';
import styles from './Removed.module.scss';
import { Action } from '../Action';

type Props = {
    onNotLoggedIn: () => void
}

type State = {
    type: 'loading'
} | {
    type: 'loaded'
    articles: ArticleType[]
} | {
    type: 'confirmation'
    articles: ArticleType[]
}

export const Removed = ({ onNotLoggedIn }: Props) => {
    const [state, setState] = useState<State>({ type: 'loading' });
    const token = useToken();
    const [isOffline] = useOffline();

    useEffect(() => {
        const dataRequest = isOffline
            ? getRemovedOffline()
            : getRemovedOnline(token, onNotLoggedIn);

        dataRequest.then((articles) => setState({
            type: 'loaded',
            articles,
        }));
    }, [ token, isOffline ]);

    const erase = () => {
        api.removeAll(true, token).then((result) => {
            if (isError(result)) {
                alert(result.error);

                if (result.code === 401) {
                    onNotLoggedIn();
                }

                return;
            }

            setState({ type: 'loaded', articles: [] });
        })
    };

    switch (state.type) {
        case 'loading':
            return (
                <Processing>
                    loading...
                </Processing>
            );

        case 'loaded':
        case 'confirmation':
            const [ firstArticle ] = state.articles;

            if (!firstArticle) {
                return (
                    <Processing>
                        Trash can is empty...
                    </Processing>
                );
            }

            return (
                <>
                    <section className={ styles.container }>
                        <Left>
                            { left(state.articles.length, state.articles.length) }
                        </Left>

                        <section className={ styles.list }>
                            { state.articles
                                .map(({ id, title }) => (
                                    <Article
                                        key={ id }
                                        id={ id }
                                        title={ title }
                                        onRestore={() => setState({
                                            ...state,
                                            articles: state.articles.filter(
                                                article => article.id !== id
                                            )
                                        })}
                                        onRemove={() => setState({
                                                ...state,
                                                articles: state.articles.filter(
                                                    article => article.id !== id
                                                )
                                            })
                                        }
                                    />
                                )) }
                        </section>
                    </section>

                    { state.type === 'confirmation'
                        ? (
                            <Popup
                                title='Confirmation'
                                actions={(
                                    <section className={ styles.actions }>
                                        <Button onClick={erase}>
                                            Confirm
                                        </Button>
                                        <Button onClick={() => setState({ ...state, type: 'loaded' })}>
                                            Cancel
                                        </Button>
                                    </section>
                                )}
                            >
                                Are you sure you want to clean up?
                            </Popup>
                        )
                        : (
                            <Action onClick={ () => setState({ ...state, type: 'confirmation' }) } disabled={ isOffline }>
                                <Icons.Erase />
                            </Action>
                        ) }
                </>
            )

        default:
            return unknown(state);
    }
};

async function getRemovedOnline(token: string, onNotLoggedIn: () => void): Promise<ArticleType[]> {
    const articles = await api.removed(token);

    if (isError(articles)) {
        alert(articles.error);

        if (articles.code === 401) {
            onNotLoggedIn();
        }

        throw new Error(articles.error);
    }

    return articles;
}

async function getRemovedOffline(): Promise<ArticleType[]> {
    const db = await getDB();

    const [ removed, trash ] = await Promise.all([
        db.getAll('removed'),
        db.getAll('trash')
    ]);

    db.close();

    return removed.concat(trash);
}