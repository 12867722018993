import React, { useState } from 'react';
import cx from 'classnames';

import { Tag } from '../Tag';
import { Button } from '../Button';
import { Article as ArticleContent } from '../Article';
import { useToken } from '../TokenContext';
import { useOffline } from '../OfflineProvider';
import * as Icons from '../Icons';
import { api, isError, KnownArticle } from '../../helper/api';
import { getDB } from '../../helper/db';

import styles from './Article.module.scss';

type Props = KnownArticle & {
    onRemove: () => void
    onEdit: (id: number, tags: number[]) => void
    onUpdateContent: (contentLength: number) => void
}

export const Article = ({ id, title, contentLength, tags, onRemove, onEdit, onUpdateContent }: Props) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ content, setContent ] = useState<string>()
    const token = useToken();
    const [ isOffline ] = useOffline();

    const hasContent = contentLength > 4;
    const readTime = Math.ceil(contentLength / 100);

    const remove = async () => {
        setIsLoading(true);

        const removePromise = isOffline
            ? removeArticleOffline(id)
            : removeArticleOnline(id, token);

        if (await removePromise) {
            onRemove();
        }

        setIsLoading(false);
    };

    const getContent = async () => {
        setIsLoading(true);

        try {
            const content = isOffline
                ? getContentOffline(id)
                : getContentOnline(id, token);

            setContent(await content);
        } finally {
            setIsLoading(false);
        }
    };

    const loadContent = () => {
        setIsLoading(true);

        api.loadContent(id, token).then(result => {
            if (isError(result)) {
                alert(result.error);

                setIsLoading(false);

                return;
            }

            onUpdateContent(result.contentLength);
            setContent(result.content);
            setIsLoading(false);
        });
    };

    return (
        <>
            <article className={ cx({
                [styles.article]: true,
                [styles.disabled]: isLoading
            }) }>
                <a
                    href={`https://habr.com/post/${id}`}
                    target='_blank'
                    className={ styles.link }
                >
                    {title}
                </a>

                <section className={ styles.tags }>
                    { tags.map(({ id, name }) => (
                        <Tag key={ id } selected>{ name }</Tag>
                    )) }
                </section>

                <Button icon={ <Icons.Pen /> } small className={ styles.action } onClick={ () => onEdit(id, tags.map(({ id }) => id)) } disabled={ isOffline }>Edit</Button>
                <Button
                    icon={
                        hasContent ? <Icons.Book /> : <Icons.Download />
                    }
                    small
                    className={ styles.action }
                    onClick={ hasContent ? getContent : loadContent }
                >
                    { hasContent ? `~ ${readTime} min` : 'Load' }
                </Button>
                <Button icon={ <Icons.Trash /> } small className={ styles.action } onClick={ remove }>Remove</Button>
            </article>

            { content && <ArticleContent
                id={ id }
                title={ title }
                onClose={ () => setContent(undefined) }
                content={ content }
            /> }
        </>
    );
};

async function getContentOnline(id: number, token: string): Promise<string> {
    const result = await api.content(id, token);

    if (isError(result)) {
        alert(result.error);

        throw new Error(result.error);
    }

    return result.content;
}

async function getContentOffline(id: number) {
    const db = await getDB();
    const content = await db.get('known', id);
    db.close();

    if (!content) {
        alert('Not found!');

        throw new Error('Not found!');
    }

    return content.content;
}

async function removeArticleOnline(id: number, token: string): Promise<boolean> {
    const result = await api.removeArticle(id, token);

    if (!isError(result)) {
        return true;
    }

    alert(result.error);

    return false;
}

async function removeArticleOffline(id: number): Promise<boolean> {
    const db = await getDB();
    const article = await db.get('known', id);

    if (article) {
        await Promise.all([
            db.add('trash', article),
            db.delete('known', id)
        ]);
    }

    db.close();

    return Boolean(article);
}