import React, { useState, useEffect } from 'react';
import { deleteDB } from 'idb';
import cx from 'classnames';

import { Processing } from '../Processing';
import { useToken } from '../TokenContext';
import { Left } from '../Left';
import { Popup } from '../Popup';
import { Action } from '../Action';
import { Button } from '../Button';
import { Input } from '../Input';
import * as Icons from '../Icons';
import { useOffline } from '../OfflineProvider';
import { api, isError, Article as ArticleType, Tag, OfflineData } from '../../helper/api';
import { unknown } from '../../helper/unknown';
import { getDB } from '../../helper/db';

import { Article } from './Article';
import styles from './Unsorted.module.scss';

type Props = {
    onNotLoggedIn: () => void
}

type State = {
    type: 'loading'
} | {
    type: 'loaded'
    articles: ArticleType[],
    tags: Tag[]
    actions: boolean
} | {
    type: 'adding'
    articles: ArticleType[],
    tags: Tag[]
    id: number
} | {
    type: 'confirmation'
    articles: ArticleType[],
    tags: Tag[]
    id: number
} | {
    type: 'cleanup'
    articles: ArticleType[],
    tags: Tag[]
    trash: number
}

export const Unsorted = ({ onNotLoggedIn }: Props) => {
    const [state, setState] = useState<State>({ type: 'loading' });
    const [isOffline, setOffline] = useOffline();
    const token = useToken();

    useEffect(() => {
        const dataRequest = isOffline
            ? getUnsortedOffline()
            : getUnsortedOnline(token, onNotLoggedIn);

        dataRequest.then(([ articles, tags ]) => {
            setState({ type: 'loaded', articles, tags, actions: false });
        })
    }, [ token, isOffline ]);

    const addArticle = async () => {
        if (state.type !== 'adding') {
            return
        }

        setState({ type: 'loading' });

        const result = await api.addArticle(state.id, token);

        if (isError(result)) {
            alert(result.error);

            if (result.code === 401) {
                onNotLoggedIn();
            }

            setState(state);

            return;
        }

        setState({
            ...state,
            type: 'loaded',
            articles: state.articles.concat(result),
            actions: false
        });
    };

    const grab = async () => {
        if (state.type !== 'confirmation') {
            return
        }

        setState({ type: 'loading' });

        const result = await api.refresh(true, token);

        if (isError(result)) {
            alert(result.error);

            if (result.code === 401) {
                onNotLoggedIn();
            }

            setState(state);

            return;
        }

        setState({
            ...state,
            type: 'loaded',
            articles: result,
            actions: false,
        });
    };

    switch (state.type) {
        case 'loading':
            return (
                <Processing>
                    loading...
                </Processing>
            );

        case 'loaded':
        case 'adding':
        case 'confirmation':
        case 'cleanup':
            const [ firstArticle ] = state.articles;

            const pop = () => setState({
                ...state,
                articles: state.articles.filter(
                    ({ id }) => id !== firstArticle.id
                )
            });
            const withActions = state.type === 'loaded' && state.actions;

            const goOffline = async () => {
                if (state.type !== 'loaded') {
                    return;
                }

                setState({ type: 'loading' });

                const data = await api.offline(token);

                if (isError(data)) {
                    alert(data.error);

                    if (data.code === 401) {
                        onNotLoggedIn();
                    }

                    return;
                }

                await saveOfflineData(data);

                setState({ ...state, actions: false });
                setOffline(true);
            };

            const goOnline = async () => {
                if (state.type === 'loaded') {
                    const db = await getDB();

                    setState({
                        ...state,
                        type: 'cleanup',
                        trash: await db.count('trash')
                    });

                    db.close();
                }
            };

            const cleanup = async (clean: boolean) => {
                if (state.type !== 'cleanup') {
                    return;
                }

                setState({ type: 'loading' });

                if (clean) {
                    const db = await getDB();
                    const trash = await db.getAll('trash');
                    const ids = trash.map(
                        ({ id }) => id
                    );
                    const result = await api.clean(ids, token);

                    db.close();

                    if (isError(result)) {
                        alert(result.error);

                        if (result.code === 401) {
                            onNotLoggedIn();
                        }

                        return;
                    }
                }

                await cleanOfflineData();

                setOffline(false);
            };

            return (
                <>
                    { firstArticle
                        ? (
                            <section className={ styles.container }>
                                <Left>
                                    { `${state.articles.length} items(s)` }
                                </Left>

                                <Article
                                    id={firstArticle.id}
                                    title={firstArticle.title}
                                    tags={state.tags}
                                    onRemove={pop}
                                    onSave={pop}
                                />
                            </section>
                        ) : (
                            <Processing>
                                no new articles...
                            </Processing>
                        ) }

                    { state.type === 'adding'
                        && (
                            <Popup
                                title='Add article'
                                actions={(
                                    <section className={ styles.actions }>
                                        <Button onClick={ addArticle }>
                                            Add
                                        </Button>
                                        <Button onClick={() => setState({ ...state, type: 'loaded', actions: false })}>
                                            Cancel
                                        </Button>
                                    </section>
                                )}
                            >
                                <div className={ styles.form }>
                                    <Input
                                        value={ state.id ? state.id.toString() : '' }
                                        autoFocus
                                        number
                                        onChange={ (value) => {
                                            const intValue = Number(value);

                                            if (Number.isNaN(intValue)) {
                                                return;
                                            }

                                            setState({ ...state, id: intValue });
                                        } }
                                    />
                                </div>
                            </Popup>
                        ) }

                    { state.type === 'confirmation'
                        && (
                            <Popup
                                title='Confirmation'
                                actions={(
                                    <section className={ styles.actions }>
                                        <Button onClick={grab}>
                                            Confirm
                                        </Button>
                                        <Button onClick={() => setState({ ...state, type: 'loaded', actions: false })}>
                                            Cancel
                                        </Button>
                                    </section>
                                )}
                            >
                                Are you sure you want to grab manually?
                            </Popup>
                        ) }

                    { state.type === 'cleanup'
                        && (
                            <Popup
                                title='Confirmation'
                                actions={(
                                    <section className={ styles.actions }>
                                        { state.trash === 0
                                            ? (
                                                <Button onClick={ () => cleanup(false) }>
                                                    Confirm
                                                </Button>
                                            )
                                            : (
                                                <>
                                                    <Button onClick={ () => cleanup(true) }>
                                                        Yes
                                                    </Button>
                                                    <Button onClick={ () => cleanup(false) }>
                                                        No
                                                    </Button>
                                                </>
                                            ) }
                                        <Button onClick={() => setState({ ...state, type: 'loaded', actions: false })}>
                                            Cancel
                                        </Button>
                                    </section>
                                )}
                            >
                                { state.trash === 0
                                    ? 'Are you sure you want to leave offline mode?'
                                    : `Several articles has been removed (${state.trash}). Do you want to remove those?` }
                            </Popup>
                        ) }

                    { state.type === 'loaded'
                        && (
                            <>
                                <Action
                                    onClick={ () => setState({ ...state, actions: false }) }
                                    cancel
                                    className={ cx(styles.action, {
                                        [styles.hidden]: !withActions
                                    }) }
                                >
                                    <Icons.Cross />
                                </Action>
                                <Action
                                    onClick={ () => setState({ ...state, type: 'adding', id: 0 }) }
                                    className={ cx(styles.action, withActions ? styles.add : styles.hidden) }
                                    disabled={ isOffline }
                                >
                                    <Icons.Plus />
                                </Action>
                                <Action
                                    onClick={ () => setState({ ...state, type: 'confirmation', id: 0 }) }
                                    className={ cx(styles.action, withActions ? styles.refresh : styles.hidden) }
                                    disabled={ isOffline }
                                >
                                    <Icons.Refresh />
                                </Action>
                                <Action
                                    onClick={ isOffline ? goOnline : goOffline }
                                    className={ cx(styles.action, withActions ? styles.offline : styles.hidden) }
                                >
                                    { isOffline ? <Icons.PlaneSlash /> : <Icons.Plane /> }
                                </Action>
                                <Action
                                    onClick={ () => setState({ ...state, actions: true }) }
                                    className={ cx(styles.action, {
                                        [styles.hidden]: withActions
                                    }) }
                                >
                                    <Icons.Lines />
                                </Action>
                            </>
                        ) }
                </>
            );

        default:
            return unknown(state);
    }
};

async function saveOfflineData(data: OfflineData) {
    const db = await getDB();

    const tags = db.transaction('tags', 'readwrite');
    const unsorted = db.transaction('unsorted', 'readwrite');
    const sorted = db.transaction('sorted', 'readwrite');
    const known = db.transaction('known', 'readwrite');
    const removed = db.transaction('removed', 'readwrite');

    const addTags = data.tags.map(tag => tags.store.add(tag));
    const addUnsorted = data.unsorted.map(article => unsorted.store.add(article));
    const addSorted = data.sorted.map(article => sorted.store.add(article));
    const addKnown = data.known.map(article => known.store.add(article));
    const addRemoved = data.removed.map(article => removed.store.add(article));

    await Promise.all([
        ...addTags,
        ...addUnsorted,
        ...addSorted,
        ...addKnown,
        ...addRemoved
    ]);

    db.close();
}

function cleanOfflineData() {
    return deleteDB('habr-feed');
}

type Data = [ ArticleType[], Tag[] ];

async function getUnsortedOnline(token: string, onNotLoggedIn: () => void): Promise<Data> {
    const [ articles, tags ] = await Promise.all([
        api.unsorted(token),
        api.tags(token)
    ]);

    if (isError(articles)) {
        alert(articles.error);

        if (articles.code === 401) {
            onNotLoggedIn();
        }

        throw new Error(articles.error);
    }

    if (isError(tags)) {
        alert(tags.error);

        if (tags.code === 401) {
            onNotLoggedIn();
        }

        throw new Error(tags.error);
    }

    return [ articles, tags ];
}

async function getUnsortedOffline(): Promise<Data> {
    const db = await getDB();

    const result = await Promise.all([
        db.getAll('unsorted'),
        db.getAll('tags'),
    ]);

    db.close();

    return result;
}
