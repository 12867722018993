import React, { ChangeEvent } from 'react';
import cx from 'classnames';

import styles from './Input.module.scss';

type Props = {
    type?: 'text' | 'password'
    value: string
    className?: string
    autoFocus?: boolean
    onChange: (value: string) => void
    number?: boolean
}

export const Input = ({ type = 'text', value, className, autoFocus, number, onChange }: Props) => (
    <input
        type={ type }
        className={ cx(styles.input, className) }
        value={ value }
        autoFocus={ autoFocus }
        pattern={ number ? '[0-9]*' : undefined }
        onChange={ ({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.value) }
    />
)