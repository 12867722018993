import React, { useState } from 'react';

import { Button } from '../Button';
import { Tag } from '../Tag';
import { Processing } from '../Processing';
import { useToken } from '../TokenContext';
import * as Icons from '../Icons';
import { useOffline } from '../OfflineProvider';
import { unknown } from '../../helper/unknown';
import { api, isError, Tag as TagType } from '../../helper/api';
import { getDB } from '../../helper/db';

import styles from './Article.module.scss';

const toggleTag = (tags: number[], tag: number): number[] => tags.includes(tag)
    ? tags.filter(
        id => id !== tag
    ) : tags.concat([ tag ]);

type Props = {
    id: number
    title: string
    tags: TagType[]
    onRemove: () => void
    onSave: () => void
}

type State = {
    type: 'idle'
    tags: number[]
} | {
    type: 'removing'
} | {
    type: 'saving'
}

export const Article = ({ id, title, tags, onRemove, onSave }: Props) => {
    const initialTags = tags.filter(isNew).map(getId);
    const [ state, setState ] = useState<State>({
        type: 'idle',
        tags: initialTags
    });
    const token = useToken();
    const [ isOffline ] = useOffline();

    const remove = async () => {
        setState({ type: 'removing' });

        const removePromise = isOffline
            ? removeArticleOffline(id)
            : removeArticleOnline(id, token);

        if (await removePromise) {
            onRemove();
        }

        setState({ type: 'idle', tags: initialTags });
    };

    const save = () => {
        if (state.type !== 'idle') {
            return;
        }

        setState({ type: 'saving' });

        api.sortArticle(id, state.tags, token).then(result => {
            if (isError(result)) {
                alert(result.error);

                setState(state);

                return;
            }

            onSave();

            setState({ type: 'idle', tags: initialTags });
        });
    };

    switch (state.type) {
        case 'idle':
            return (
                <article className={ styles.article }>
                    <a href={`https://habr.com/post/${id}`} target='_blank' className={ styles.link }>{title}</a>

                    <section className={ styles.tags }>
                        {tags.map(
                            ({ id, name }) => (
                                <Tag
                                    key={id}
                                    selected={ state.tags.includes(id) }
                                    onClick={() => setState({
                                        ...state,
                                        tags: toggleTag(state.tags, id)
                                    })}
                                >
                                    {name}
                                </Tag>
                            )
                        )}
                    </section>

                    <section className={ styles.actions }>
                        <Button icon={ <Icons.Bookmark /> } onClick={ save } className={ styles.action } disabled={ isOffline } >
                            Sort
                        </Button>
                        <Button icon={ <Icons.Trash /> } onClick={ remove } className={ styles.action }>
                            Remove
                        </Button>
                    </section>
                </article>
            );

        case 'removing':
            return (
                <Processing>removing...</Processing>
            );

        case 'saving':
            return (
                <Processing>
                    saving...
                </Processing>
            );

        default:
            return unknown(state);
    }
};

async function removeArticleOnline(id: number, token: string): Promise<boolean> {
    const result = await api.removeArticle(id, token);

    if (!isError(result)) {
        return true;
    }

    alert(result.error);

    return false;
}

async function removeArticleOffline(id: number): Promise<boolean> {
    const db = await getDB();
    const article = await db.get('unsorted', id);

    if (article) {
        await Promise.all([
            db.add('trash', article),
            db.delete('unsorted', id)
        ]);
    }

    db.close();

    return Boolean(article);
}

function isNew({ name }: TagType) {
    return name === 'new';
}

function getId({ id }: TagType) {
    return id;
}