type LoginParams = {
    login: string
    password: string
}

export type Article = {
    id: number,
    title: string
};

export type Tag = {
    id: number,
    name: string
};

export type ArticleSort = {
    id: number,
    tags: number[]
};

export type SortedArticle = Article & {
    tags: Tag[]
    contentLength: number
}

export type KnownArticle = Article & {
    tags: Tag[]
    contentLength: number
}

export type ReadParams = {
    id: number
}

export type OfflineArticle = Article & {
    tags: Tag[]
    content: string
    contentLength: number
};

export type OfflineData = {
    tags: Tag[]
    unsorted: OfflineArticle[]
    sorted: OfflineArticle[]
    known: OfflineArticle[]
    removed: OfflineArticle[]
}

export const api = {
    login: (params: LoginParams) => post<LoginParams, { token: string }>('login', params),
    unsorted: (token: string) => get<{}, Article[]>('unsorted', token),
    tags: (token: string) => get<{}, Tag[]>('tags', token),
    removeArticle: (id: number, token: string) => post<{ id: number }, { id: number }>('remove', { id }, token),
    sortArticle: (id: number, tags: number[], token: string) => post<ArticleSort, ArticleSort>('sort', { id, tags }, token),
    sorted: (token: string) => get<{}, SortedArticle[]>('sorted', token),
    read: (id: number, token: string) => post<ReadParams, ReadParams>('read', { id }, token),
    known: (token: string) => get<{}, KnownArticle[]>('read', token),
    deleteArticle: (id: number, token: string) => post<{ id: number }, { id: number }>('delete', { id }, token),
    removed: (token: string) => get<{}, Article[]>('removed', token),
    restore: (id: number, token: string) => post<{ id: number }, { id: number}>('restore', { id }, token),
    removeAll: (confirm: boolean, token: string) => post<{ confirm: boolean }, { ok: boolean }>('recycle', { confirm }, token),
    refresh: (confirm: boolean, token: string) => post<{ confirm: boolean }, Article[]>('refresh', { confirm }, token),
    addArticle: (id: number, token: string) => post<{ id: number }, Article>('article', { id }, token),
    content: (id: number, token: string) => get<{}, { content: string }>(`/content/${id}`, token),
    addTag: (name: string, token: string) => post<{ name: string }, { id: number }>('/tag', { name }, token),
    loadContent: (id: number, token: string) => post<{ id: number }, { content: string, contentLength: number }>('/content', { id }, token),
    offline: (token: string) => get<{}, OfflineData>('/offline', token),
    clean: (ids: number[], token: string) => post<{ ids: number[] }, { ok: boolean }>('/clean', { ids }, token),
    reloadContent: (id: number, token: string) => post<{ id: number }, { content: string }>('/reload', { id }, token)
};

type Error = {
    code: number
    error: string
}

type Result<T> = T | Error

export const isError = <T extends {}>(result: Result<T>): result is Error => 'error' in result;

async function post<T, R>(api: string, params: T, token?: string): Promise<Result<R>> {
    const authHeader: {} | { authorization: string } = token
        ? { authorization: `Bearer ${btoa(token)}` }
        : {};
    const response = await fetch(`/api/${api}`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'content-type': 'application/json',
            ...authHeader
        }
    });
    const code = response.status;
    const json: Result<R> = await response.json();

    return isError(json)
        ? { ...json, code }
        : json;
};

async function get<T extends {}, R>(api: string, token: string, params?: T): Promise<Result<R>> {
    const queryString = params
        ? `?${new URLSearchParams(params)}`
        : '';

    const response = await fetch(`/api/${api}${queryString}`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${btoa(token)}`
        }
    });
    const code = response.status;
    const json: Result<R> = await response.json();

    return isError(json)
        ? { ...json, code }
        : json;
}