import React, { useState } from 'react';

import { Input } from '../Input';
import { Button } from '../Button';
import { api, isError } from '../../helper/api';

import styles from './Login.module.scss';

type Props = {
    onLogin: (token: string) => void
}

type State = {
    login: string
    password: string
}

export const Login = ({ onLogin }: Props) => {
    const [ state, setState ] = useState<State>({
        login: '',
        password: ''
    });

    const handleLoginChange = (login: string) => setState({
        ...state,
        login
    });
    const handlePasswordChange = (password: string) => setState({
        ...state,
        password
    });
    const login = async () => {
        const result = await api.login(state);

        if (isError(result)) {
            alert(result.error);
        } else {
            onLogin(result.token);
        }
    };

    return (
        <section className={ styles.container }>
            <div className={ styles.form }>
                <Input value={ state.login } onChange={ handleLoginChange } />
                <Input type='password' value={ state.password } onChange={ handlePasswordChange } />
                <Button onClick={ login }>Login</Button>
            </div>
        </section>
    );
};