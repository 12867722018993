import React, { useState } from 'react';

import { Button } from '../Button';
import { useToken } from '../TokenContext';
import { useOffline } from '../OfflineProvider';
import * as Icons from '../Icons';
import { api, isError, Article as ArticleType } from '../../helper/api';

import styles from './Article.module.scss';

type Props = ArticleType & {
    onRestore: () => void
    onRemove: () => void
}

export const Article = ({ id, title, onRestore, onRemove }: Props) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const token = useToken();
    const [ isOffline ] = useOffline();

    if (isLoading) {
        return null;
    }

    const restore = () => {
        setIsLoading(true);

        api.restore(id, token).then(result => {
            if (isError(result)) {
                alert(result.error);

                setIsLoading(false);

                return;
            }

            onRestore();
        });
    };

    const remove = () => {
        setIsLoading(true);

        api.deleteArticle(id, token).then(result => {
            if (isError(result)) {
                alert(result.error);

                setIsLoading(false);

                return;
            }

            onRemove();
        });
    };

    return (
        <article className={ styles.article }>
            <a
                href={`https://habr.com/post/${id}`}
                target='_blank'
                className={ styles.link }
            >
                {title}
            </a>

            <section className={ styles.actions }>
                <Button icon={ <Icons.Restore /> } small className={ styles.action } onClick={ restore } disabled={ isOffline }>Restore</Button>
                <Button icon={ <Icons.Ban /> } small className={ styles.action } onClick={ remove } disabled={ isOffline }>Remove</Button>
            </section>
        </article>
    );
};