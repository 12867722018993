import React, { useState } from 'react';
import cx from 'classnames';

import { Unsorted } from '../Unsorted';
import { Sorted } from '../Sorted';
import { Read } from '../Read';
import { Removed } from '../Removed';
import * as Icons from '../Icons';
import { unknown } from '../../helper/unknown';

import styles from './Container.module.scss';

const getContentComponent = (state: State) => {
    switch (state) {
        case 'unsorted':
            return Unsorted;

        case 'sorted':
            return Sorted;

        case 'read':
            return Read;

        case 'removed':
            return Removed;

        default:
            return unknown(state);
    }
};

type Props = {
    onNotLoggedIn: () => void
};

type State = 'unsorted' | 'sorted' | 'read' | 'removed';

export const Container = ({ onNotLoggedIn }: Props) => {
    const [ state, setState ] = useState<State>('unsorted');

    const Content = getContentComponent(state);

    return (
        <main className={ styles.container }>
            <section className={ styles.body }>
                <Content onNotLoggedIn={ onNotLoggedIn } />
            </section>
            <nav className={ styles.menu }>
                <button className={ styles.tab } onClick={ () => setState('unsorted') }>
                    <Icons.Filter className={ cx({
                        [styles.icon]: true,
                        [styles.active]: state === 'unsorted'
                    }) } />
                </button>
                <button className={ styles.tab }  onClick={ () => setState('sorted') }>
                    <Icons.Bookmark className={ cx({
                        [styles.icon]: true,
                        [styles.active]: state === 'sorted'
                    }) } />
                </button>
                <button className={ styles.tab }  onClick={ () => setState('read') }>
                    <Icons.CheckedList className={ cx({
                        [styles.icon]: true,
                        [styles.active]: state === 'read'
                    }) } />
                </button>
                <button className={ styles.tab }  onClick={ () => setState('removed') }>
                    <Icons.Trash className={ cx({
                        [styles.icon]: true,
                        [styles.active]: state === 'removed'
                    }) } />
                </button>
            </nav>
        </main>
    );
};