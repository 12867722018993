import React from 'react';
import cx from 'classnames';

import { useOffline } from '../OfflineProvider';

import styles from './Left.module.scss';

export const Left: React.FC = ({ children }) => {
    const [ isOffline ] = useOffline();

    return (
        <>
            <section className={ cx(styles.left, {
                [styles.offline]: isOffline
            }) }>
                { children }
            </section>
            { isOffline && <div className={ styles.offlineHint }>offline mode</div> }
        </>
    )
};