import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const OfflineContext = createContext<[ boolean, (offline?: boolean) => void ]>([
    false,
    () => {}
]);

const isOffline = () => Boolean(localStorage.getItem('offline'));

export const OfflineProvider: React.FC = ({ children }) => {
    const [ offline, setOffline ] = useState(isOffline());

    useEffect(() => {
        const storageHandler = () => setOffline(isOffline());

        window.addEventListener('storage', storageHandler);

        return () => window.removeEventListener('storage', storageHandler);
    }, []);

    const toggle = useCallback((value?: boolean) => setOffline(oldOffline => {
        const newValue = value === undefined
            ? !oldOffline
            : value;

        newValue
            ? localStorage.setItem('offline', 'true')
            : localStorage.removeItem('offline');

        return newValue;
    }), []);

    return (
        <OfflineContext.Provider value={[ offline, toggle ]}>
            { children }
        </OfflineContext.Provider>
    )
};

export const useOffline = () => useContext(OfflineContext);