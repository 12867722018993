import { openDB, DBSchema } from 'idb';

import { Tag, OfflineArticle, OfflineData } from './api';

interface HabrFeedDB extends DBSchema {
    tags: {
        key: number,
        value: Tag
    };
    unsorted: {
        key: number
        value: OfflineArticle
    };
    sorted: {
        key: number
        value: OfflineArticle
    };
    known: {
        key: number
        value: OfflineArticle
    };
    removed: {
        key: number
        value: OfflineArticle
    };
    trash: {
        key: number
        value: OfflineArticle
    }
}

export function getDB() {
    return openDB<HabrFeedDB>('habr-feed', 1, {
        upgrade(db) {
            db.createObjectStore('tags', { keyPath: 'id' });
            db.createObjectStore('unsorted', { keyPath: 'id' });
            db.createObjectStore('sorted', { keyPath: 'id' });
            db.createObjectStore('known', { keyPath: 'id' });
            db.createObjectStore('removed', { keyPath: 'id' });
            db.createObjectStore('trash', { keyPath: 'id' });
        }
    })
}
