import React from 'react';
import cx from 'classnames';

import styles from './Tag.module.scss';

type Props = {
    selected?: boolean
    simple?: boolean
    amount?: number
    onClick?: () => void
}

export const Tag: React.FC<Props> = ({ children, selected, simple = false, onClick }) => (
    <button
        className={ cx({
            [styles.tag]: true,
            [styles.selected]: selected
        }) }
        onClick={onClick}
    >
        { simple ? null : '#' }{children}
    </button>
);