import React, { useState } from 'react';

import { Button } from '../Button';
import { useToken } from '../TokenContext';
import { useOffline } from '../OfflineProvider';
import { api, isError } from '../../helper/api';

import styles from './Article.module.scss';

type Props = {
    id: number
    title: string
    onClose: () => void
    content: string
}

export const Article: React.FC<Props> = ({ id, title, content: initialContent, onClose }) => {
    const [ content, setContent ] = useState(initialContent);
    const [ isText, setIsText ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(false);
    const token = useToken();
    const [ isOffline ] = useOffline();

    const span = document.createElement('SPAN');
    span.innerHTML = content;
    const text = span.textContent || '';

    const reload = async () => {
        setIsLoading(true);

        const result = await api.reloadContent(id, token);

        setIsLoading(false);

        if (isError(result)) {
            alert(result.error);

            return;
        }

        setContent(result.content);
    };

    return (
        <>
            <div className={ styles.container }>
                <div className={ styles.window }>
                    <strong className={ styles.header }>
                        { title }
                    </strong>
                    { isText
                        ? (
                            <div className={ styles.content }>
                                { text.split("\n").map(paragraph => (
                                    <p>{ paragraph }</p>
                                )) }
                            </div>
                        ) : (
                            <div className={ styles.content } dangerouslySetInnerHTML={{
                                __html: content
                            }} />
                        ) }
                    <div className={ styles.actions }>
                        <Button onClick={ () => setIsText(!isText) }>
                            { isText ? 'Text' : 'HTML' }
                        </Button>
                        <Button onClick={ reload } disabled={ isOffline || isLoading }>
                            Reload
                        </Button>
                        <Button onClick={ onClose }>Close</Button>
                    </div>
                </div>
            </div>
        </>
    );
};