import React from 'react';

import styles from './Popup.module.scss';

type Props = {
    title: string
    action?: React.ReactNode
    actions: React.ReactNode
}

export const Popup: React.FC<Props> = ({ title, action, children, actions }) => (
    <>
        <div className={ styles.backdrop } />
        <div className={ styles.container }>
            <div className={ styles.window }>
                <strong className={ styles.header }>
                    { title }
                    { action && <div className={ styles.action }>{ action }</div> }
                </strong>
                <div className={ styles.content }>
                    { children }
                </div>
                <div className={ styles.actions }>
                    { actions }
                </div>
            </div>
        </div>
    </>
);