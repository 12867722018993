import React, { useState, useEffect } from 'react';

import { Processing } from '../Processing';
import { TagSelector } from '../TagSelector';
import { Action } from '../Action';
import { useToken } from '../TokenContext';
import { Left } from '../Left';
import { Overlay } from '../Overlay';
import * as Icons from '../Icons';
import { useOffline } from '../OfflineProvider';
import { ReachInput } from '../ReachInput';
import { api, isError, SortedArticle, Tag as TagType } from '../../helper/api';
import { unknown } from '../../helper/unknown';
import { getDB } from '../../helper/db';

import { Article } from './Article';
import styles from './Sorted.module.scss';

type Props = {
    onNotLoggedIn: () => void
}

type Filter = null | {
    title: string
    tags: number[]
}

type State = {
    type: 'loading'
} | {
    type: 'loaded' | 'selecting-tags' | 'reloading'
    articles: SortedArticle[],
    tags: TagType[]
    filter: Filter
} | {
    type: 'editing'
    articles: SortedArticle[],
    tags: TagType[]
    filter: Filter
    currentId: number
    currentTags: number[]
}

export const Sorted = ({ onNotLoggedIn }: Props) => {
    const [state, setState] = useState<State>({ type: 'loading' });
    const [isOffline] = useOffline();
    const token = useToken();

    useEffect(() => {
        const dataRequest = isOffline
            ? getSortedOffline()
            : getSortedOnline(token, onNotLoggedIn);

        dataRequest.then(([ articles, tags ]) => {
            setState({
                type: 'loaded',
                articles,
                tags,
                filter: null,
            });
        });
    }, [ token, isOffline ]);

    switch (state.type) {
        case 'loading':
            return (
                <Processing>
                    loading...
                </Processing>
            );

        case 'loaded':
        case 'selecting-tags':
        case 'editing':
        case 'reloading':
            const [ firstArticle ] = state.articles;

            if (!firstArticle) {
                return (
                    <Processing>
                        No articles to read...
                    </Processing>
                );
            }

            const filteredArticles = state.articles
                .filter(articleFilter(state.filter))
                .sort(
                    (article1, article2) => (article1.contentLength || Infinity) - (article2.contentLength || Infinity)
                );
            const grouped = state.articles.reduce<Record<number, number>>((reduced, { tags }) => {
                if (!tags.length) {
                    reduced[-1] = (reduced[-1] || 0) + 1;

                    return reduced;
                }

                tags.forEach(({ id }) => {
                    reduced[id] = (reduced[id] || 0) + 1
                });

                return reduced
            }, {});
            const mapTag = ({ id, name }: TagType) => ({
                id,
                name,
                amount: grouped[id]
            });
            const tags = state.tags
                .map(mapTag);
            const usedTags = state.tags
                .filter(({ id }) => grouped[id] || state.filter?.tags?.includes(id))
                .concat([{ id: -1, name: 'no-tag' }])
                .map(mapTag);

            const onUpdateTags = (tags: TagType[]) => setState({
                ...state,
                tags
            });

            return (
                <>
                    <section className={ styles.container }>
                        <Left>
                            { state.filter
                                ? (
                                    <ReachInput
                                        value={ state.filter.title }
                                        info={ filteredArticles.length.toString() }
                                        extended={ Boolean(state.filter.tags.length) }
                                        onChange={ (title) => setState({ ...state, filter: { tags: state.filter?.tags || [], title } }) }
                                        onExtended={ () => setState({ ...state, type: 'selecting-tags' }) }
                                    />
                                ) : `${state.articles.length} item(s)` }
                        </Left>

                        <section className={ styles.list }>
                            { filteredArticles
                                .map(({ id, title, contentLength, tags }) => (
                                    <Article
                                        key={ id }
                                        id={ id }
                                        title={ title }
                                        contentLength={ contentLength }
                                        tags={ tags }
                                        onRemove={() => {
                                            setState({
                                                ...state,
                                                articles: state.articles.filter(
                                                    article => article.id !== id
                                                )
                                            })
                                        }}
                                        onEdit={(currentId, currentTags) => setState({ ...state, type: 'editing', currentId, currentTags }) }
                                        onUpdateContent={(contentLength) => setState({
                                            ...state,
                                            articles: state.articles.map(article => article.id === id
                                                ? { ...article, contentLength }
                                                : article)
                                        })}
                                    />
                                )) }
                        </section>
                    </section>

                    { state.filter
                        ? (
                            <Action
                                onClick={ () => setState({ ...state, filter: null }) }
                                cancel
                            >
                                <Icons.Cross />
                            </Action>
                        ) : (
                            <Action onClick={ () => setState({ ...state, filter: { title: '', tags: [] } }) }>
                                <Icons.Filter />
                            </Action>
                        )
                    }

                    { state.type === 'selecting-tags' && state.filter && <TagSelector
                        title='Filter'
                        action='Apply'
                        tags={ usedTags }
                        value={ state.filter.tags }
                        onChange={ (tags) => setState({ ...state, filter: { title: state.filter?.title || '', tags }, type: 'loaded' }) }
                        onCancel={ () => setState({ ...state, type: 'loaded' }) }
                        onUpdateTags={ onUpdateTags }
                    /> }

                    { state.type === 'editing' && <TagSelector
                        title='Edit'
                        action='Save'
                        tags={ tags }
                        value={ state.currentTags }
                        onUpdateTags={ onUpdateTags }
                        onChange={ (newTags) => {
                            const { currentId, currentTags, ...rest } = state;

                            setState({ ...state, type: 'reloading' });

                            api.sortArticle(currentId, newTags, token).then(result => {
                                if (isError(result)) {
                                    alert(result.error);

                                    setState({ ...state, currentTags: newTags });

                                    return;
                                }

                                setState({
                                    ...rest,
                                    type: 'loaded',
                                    articles: state.articles.map(
                                        article => article.id === currentId
                                            ? { ...article, tags: state.tags.filter(
                                                ({ id }) => newTags.includes(id)
                                            ) }
                                            : article
                                    )
                                })
                            });
                        }}
                        onCancel={ () => {
                            const { currentId, currentTags, ...rest } = state;

                            setState({ ...rest, type: 'loaded' });
                        } }
                    /> }

                    { state.type === 'reloading' && <Overlay /> }
                </>
            )

        default:
            return unknown(state);
    }
};

function articleFilter(filter: Filter) {
    if (!filter) {
        return () => true;
    }

    const { title, tags } = filter;
    const lowerTitle = title.toLowerCase();

    return (article: SortedArticle) => {
        const matchName = !lowerTitle || article.title.toLowerCase().includes(lowerTitle);
        const matchTags = tagFilter(tags, article.tags);

        return matchName && matchTags;
    };
}

function tagFilter(filter: number[], tags: TagType[]) {
    if (!filter.length) {
        return true;
    }

    if (!tags.length) {
        return filter.includes(-1);
    }

    return tags.some(
        ({ id }) => filter.includes(id)
    );
}

type Data = [ SortedArticle[], TagType[] ];

async function getSortedOnline(token: string, onNotLoggedIn: () => void): Promise<Data> {
    const [ articles, tags ] = await Promise.all([
        api.sorted(token),
        api.tags(token)
    ]);

    if (isError(articles)) {
        alert(articles.error);

        if (articles.code === 401) {
            onNotLoggedIn();
        }

        throw new Error(articles.error);;
    }

    if (isError(tags)) {
        alert(tags.error);

        if (tags.code === 401) {
            onNotLoggedIn();
        }

        throw new Error(tags.error);
    }

    return [ articles, tags ];
};

async function getSortedOffline(): Promise<Data> {
    const db = await getDB();

    const result = Promise.all([
        db.getAll('sorted'),
        db.getAll('tags')
    ]);

    db.close();

    return result;
}